<template>
  <UiDrawer class="ui-data-drawer">
    <template #trigger>
      <slot
        name="trigger"
        v-bind="{innerModel, setModel}"
      ></slot>
    </template>
    <template #contents="{close}">
      <slot
        name="contents"
        v-bind="{innerModel, setModel}"
      ></slot>

      <footer class="data-drawer-footer">
        <button
          type="button"
          class="ui-button --main"
          @click="accept(); close()"
        >Aceptar</button>
        <button
          type="button"
          class="ui-button --cancel"
          @click="cancel(); close()"
        >Cancelar</button>

        <slot name="footer"></slot>
      </footer>
    </template>
  </UiDrawer>
</template>

<script>
import { UiDrawer } from '@/modules/ui/components';

export default {
  name: 'UiDataDrawer',
  components: { UiDrawer },

  props: {
    value: {
      required: false,
      default: null,
    },
  },

  data() {
    return {
      innerModel: null,
    };
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.innerModel = this.sanitizeValue(newValue);
      },
    },
  },

  methods: {
    setModel(newValue) {
      this.innerModel = newValue;
    },

    sanitizeValue(newValue) {
      return newValue ? JSON.parse(JSON.stringify(newValue)) : null;
    },

    accept() {
      this.$emit('input', JSON.parse(JSON.stringify(this.innerModel)));
    },

    cancel() {
      this.innerModel = this.sanitizeValue(this.value);
    },
  },
};
</script>